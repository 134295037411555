import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import CareerComponent from '../../../components/career/CareerComponent';
import AboutComponent from '../../../components/about/aboutComponent';

function AboutTemplate({ cmsData }) {
    
    const aboutData = cmsData?.about?.edges[0]?.node?.frontmatter ?? {}

    return (
        <div>
            <AboutComponent cmsData={aboutData} />
        </div>
    )
}

export default function About() {
    return (
      <StaticQuery
        query={graphql`
          query {
            about: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "sv-about"}}}) {
              edges {
                node {
                  frontmatter {

                      seo {
                        title
                        descr
                      }

                      hero {
                        bg {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                        heroTextList {
                          text
                        }
                      }

                      content {
                          type
                          simple {
                              bgColor
                              iconOverTitle {
                                childImageSharp {
                                    fluid(maxWidth: 2048, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                              }
                              title
                              textSection {
                                boldText
                                text
                              }
                              buttonText
                              buttonPath
                          }
                          image {
                            image {
                              childImageSharp {
                                  fluid(maxWidth: 2048, quality: 100) {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                            }
                            overlay
                            title
                            textBlocks {
                              text
                            }
                            checkTextBlocks {
                              text
                            }
                            height
                          }
                          typePoints {
                            title
                            points {
                              icon {
                                childImageSharp {
                                    fluid(maxWidth: 2048, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                              }
                              title
                              text
                            }
                          }
                      }

                      pink {
                        title
                        text
                        buttonText
                        buttonPath
                      }

                      endSection {
                        icon {
                          childImageSharp {
                              fluid(maxWidth: 2048, quality: 100) {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                        }
                        title
                        buttonText
                        buttonPath
                      }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <AboutTemplate cmsData={data} />}
      />
    );
  }
